<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card>
      <v-card-title v-if="title">
        <span class="text-h3">{{ $t(title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-if="authUser.isAdmin"
              cols="6"
              sm="6"
            >
              <v-select
                v-model="options.product"
                :disabled="type == 'edit'"
                dense
                :label="$t('select_product_*')"
                :items="['oem', 'fleet']"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              v-if="authUser.isAdmin"
              cols="6"
              sm="6"
            >
              <v-autocomplete
                v-model="options.tenant_id"
                :disabled="type == 'edit'"
                dense
                :loading="tenantLoading"
                :items="tenantLIST"
                item-text="name"
                item-value="id"
                :label="
                  options.product == 'fleet'
                    ? $t('select_fleet_*')
                    : $t('select_tenant_*')
                "
                :rules="[rules.required]"
                @change="checkTenant"
              />
            </v-col>

            <v-col
              cols="6"
              sm="6"
            >
              <v-text-field
                v-model="options.name"
                dense
                :disabled="readOnly"
                :label="$t('parts_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="6"
              sm="6"
            >
              <v-text-field
                v-model="options.manufacturer"
                dense
                :disabled="readOnly"
                :label="$t('manufacturer_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="6"
              sm="6"
            >
              <v-select
                v-model="options.category"
                dense
                :disabled="readOnly"
                :loading="isCategory"
                :items="options.tenant_id ? categoryList : []"
                item-value="id"
                item-text="name"
                :label="$t('category_name_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <!-- <v-col
              cols="6"
              sm="6"
            >
              <v-text-field
                v-model="options.qty"
                dense
                :label="$t('qty_*')"
                :rules="[rules.number]"
              />
            </v-col> -->
            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                v-model="options.description"
                dense
                :disabled="readOnly"
                :label="$t('description_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                v-model="options.specification"
                dense
                :disabled="readOnly"
                :label="$t('specification')"
                clearable
              />
              <!-- :rules="[rules.required]" -->
            </v-col>
            <v-col
              cols="6"
              sm="6"
            >
              <v-text-field
                v-model="options.warranty_limit"
                dense
                :disabled="readOnly"
                :label="$t('warranty_limit')"
                clearable
              />
              <!-- :rules="[rules.number]" -->
            </v-col>
            <v-col
              cols="6"
              sm="6"
            >
              <v-select
                v-model="options.warranty_type"
                dense
                :disabled="readOnly"
                :items="[
                  { id: 'odo_meter', name: 'ODO Meter' },
                  { id: 'months', name: 'Months' },
                ]"
                item-value="id"
                item-text="name"
                :label="$t('warranty_type')"
                clearable
              />
              <!-- :rules="[rules.required]" -->
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small v-if="!readOnly">{{ $t("indicates_required_field") }}</small>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
        <v-btn
          v-if="!readOnly"
          color="primary"
          text
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  components: {},
  mixins: [validationRules],
  props: {
    type: {
      type: String,
      default() {
        return {
          time: null,
        };
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      options: {
        tenant_id: null,
      },

      //
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantOptions",
      tenantsOEM: "tenantsManagement/getTenantsOEM",
      tenantsFLEET: "tenantsManagement/getTenantsFLEET",
      //
      isCategory: "category/getLoading",
      categoryList: "category/getList",
      //
    }),
    tenantLIST() {
      if (this.options.product == "oem") {
        return this.tenantsOEM;
      } else if (this.options.product == "fleet") {
        return this.tenantsFLEET;
      } else {
        return [];
      }
    },
    title() {
      if (this.readOnly) {
        return "view";
      }
      if (this.type == "create") {
        return "add_new";
      } else if (this.type == "edit") {
        return "edit";
      } else {
        return "";
      }
    },
  },
  watch: {
    // "options.tenant_id": {
    //   handler: async function (val) {
    //     // this.checkTenant(val);
    //     if (this.type == "create") this.fetchDependents(val);
    //   },
    // },
  },
  async mounted() {
    if (this.type == "create") {
      if (this.authUser.isAdmin) {
        // this.fetchDependents(this.authUser.tenant_id);
      } else {
        this.options.tenant_id = this.authUser.tenant_id;
        this.fetchDependents(this.authUser.tenant_id);
      }
    }

    if (this.type == "edit") {
      this.options = { ...this.item };
      this.fetchDependents(this.options.tenant_id);
    }
  },
  methods: {
    saveItem() {
      if (this.$refs.form.validate()) {
        this.$emit("onsave", this.options);
      }
    },
    closeForm() {
      // this.$refs.form.reset();
      this.$emit("onclose");
    },

    async fetchDependents() {
      this.getCategoryList();
    },
    async getCategoryList() {
      const params = {
        product: this.options.product,
        // tenant_id: this.options.tenant_id,
        // fleetId: this.options.fleetId,
        // oem_b2b_ids: this.options.oem_b2b_ids,
      };
      if (params.product == "oem") {
        params.tenant_id = this.options.tenant_id;
      } else if (params.product == "fleet") {
        params.fleetId = this.options.tenant_id;
      }
      await this.$store.dispatch("category/list", params);
    },

    checkTenant(val) {
      delete this.options.fleetId;
      delete this.options.oem_b2b_ids;
      if (val.product == "oem") {
        this.options.tenant_id = val.id;
      } else if (val.product == "fleet") {
        this.options.fleetId = val.id;
        this.options.oem_b2b_ids = val.oem_b2b_ids;
        this.options.tenant_id = val.parent_tenant_ids[0];
      }
      this.fetchDependents();
    },

    //
  },
};
</script>
